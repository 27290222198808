<template>
  <div class="selectmanage">
    <el-button v-if="debug" style="z-index: 19999; color: black; position: relative" @click="scrollToCenter">test</el-button>
    <div class="loading-container" :class="{ fade: loadingFade }" v-if="loading" @click.prevent="onNothingClick">
      <div class="loading-title">
        加载中，请耐心等待<span class="percent">{{ percentString }}</span>
      </div>
      <div class="loading-icon"><i class="el-icon-loading"></i></div>
    </div>
    <div class="vote-main">
      <div class="pics-main">
        <div class="pic-left" @click="onPicLeft(1)">
          <div class="left-icon">
            <div class="el-icon-arrow-left"></div>
          </div>
        </div>
        <div class="pic-right" @click="onPicRight(1)">
          <div class="right-icon">
            <div class="el-icon-arrow-right"></div>
          </div>
        </div>
        <div class="vote-item" v-for="(pic, index) in picList" :key="'big_' + index" :class="{ left: pic.show == 1, current: pic.show == 2, right: pic.show == 3 }">
          <div class="image-large" v-if="pic.show != 0" :style="{ backgroundImage: `url(` + pic.lImgUrl + `)` }"></div>
        </div>
      </div>
    </div>
    <div class="top-hover" @mouseenter="onEnterControl()" @mouseleave="onLeaveControl()">
      <div class="post-detail" v-if="voteInfo.targets" v-bind:class="{ hide: hide }">
        <div class="post-item-sum">评审对象：{{ targetIndex + 1 }}/{{ voteInfo.targets.length }}</div>
        <div class="post-item">
          <span class="label">标题:</span>
          <i>{{ targetContent.title }} {{ !single ? '(' + (picIndexInTarget + 1) + ')' : '' }}</i>
        </div>
        <div class="post-item">
          <span class="label">作者:</span>
          <i>{{ targetContent.author }}</i>
        </div>
        <div class="post-item">
          <span class="label">时间:</span>
          <i>{{ targetContent.eventTime }}</i>
        </div>
        <div class="post-item">
          <span class="label">地点:</span>
          <i>{{ targetContent.eventAddr }}</i>
        </div>
        <div class="post-item scroll">
          <span class="label">主说明:</span>
          <i>{{ targetContent.mainText }}</i>
        </div>
      </div>
      <div class="action-detail" v-bind:class="{ hide: hide }">
        <div class="info-line">
          <div class="title">投稿活动:</div>
          <div class="name" v-if="voteInfo.at">{{ voteInfo.at.title }}</div>
        </div>
        <div class="info-line">
          <div class="title">相关评选:</div>
          <div class="name" v-if="voteInfo.atv">{{ voteInfo.atv.voteName }}</div>
        </div>
        <div class="info-line">
          <div class="title">评选规则</div>
          <div class="name" v-if="voetType == 'SELECT'">选择制（评选方式为每个评委选取一定数量稿件）</div>
          <div class="name" v-if="voetType == 'POINT'">打分制（评选方式为评委为可以为每个待评稿件打分）</div>
        </div>
        <div class="info-line"><div class="title">当前状态：</div></div>
        <!-- 打分评分 -->
        <div class="action-container">
          <div class="info-line">
            <div v-if="voetType == 'POINT'" class="action-name">打分进行中</div>
            <div v-if="voetType == 'SELECT'" class="action-name">勾选进行中</div>

            <div v-if="voetType == 'SELECT'" class="action-info">
              已选<span>{{ actionList.length }}</span> / 最大选择数<span>{{ selectLimit }}</span>
            </div>
          </div>
          <div v-if="voetType == 'POINT'" class="value-select">
            <div class="item-name">选择打分</div>
            <el-radio-group v-model="currentRadio" class="radiogroup" @change="onChangeRadio">
              <el-radio :class="{ large: k.label == 0 }" v-for="(k, radioIndex) in radioMarks" :key="'radio_' + radioIndex" :label="k.label" :value="k.name">{{ k.name }}</el-radio>
            </el-radio-group>
          </div>
          <div class="item-select" v-if="voetType == 'SELECT'">
            <div class="item-select-check">
              <el-checkbox v-model="currentCheck" @change="onCheck">选择此稿</el-checkbox>
            </div>
          </div>
          <div class="item-btn">
            <div @click="onCompleteExit">完成评选</div>
            <div @click="onTempraryExit">暂时退出</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-hover" @mouseenter="onEnterControl()" @mouseleave="onLeaveControl()">
      <div class="galary" v-bind:class="{ hide: hide }">
        <div class="galary-left-2" @click="onPicLeft(10)" title="左移10个">
          <div class="galary-icon el-icon-d-arrow-left"></div>
        </div>
        <div class="galary-left" @click="onPicLeft(5)" title="左移5个">
          <div class="galary-icon el-icon-arrow-left"></div>
        </div>
        <div class="galary-con" ref="galary-con">
          <div class="galary-list" ref="galary-list" :style="{ left: '-' + scrollLeft + 'px' }">
            <div
              class="galary-item"
              ref="galary-item"
              v-for="(p, pIndex) in picList"
              :key="'galary_' + pIndex"
              :class="{ active: picIndex == pIndex }"
              @click="onGalaryClick(pIndex)"
            >
              <img :src="p.sImgUrl" alt="" />
            </div>
          </div>
        </div>

        <div class="galary-right" @click="onPicRight(5)" title="右移5个">
          <div class="galary-icon el-icon-arrow-right"></div>
        </div>
        <div class="galary-right-2" @click="onPicRight(10)" title="右移10个">
          <div class="galary-icon el-icon-d-arrow-right"></div>
        </div>
      </div>
    </div>
    <div class="auto-hide-control">
      <div class="page-container">
        <el-pagination
          @current-change="onPageChange"
          :current-page.sync="pager.currentPage"
          :page-size="pager.pageSize"
          :hide-on-single-page="true"
          layout="prev, pager, next"
          :total="pager.total"
        ></el-pagination>
      </div>
      <el-switch v-model="autoHide" active-text="自动隐藏" inactive-text="不自动隐藏" @change="onAutoHideChange()" active-color="green" inactive-color="red"></el-switch>
      <div class="button" v-if="!hide" @click="onHideClick">隐藏</div>
      <div class="button" v-if="hide" @click="onShowClick">展开</div>
    </div>
  </div>
</template>

<script>
var timeout
import RD from '@/api/RD'
import RDUtils from '@/api/RDUtils'

export default {
  data: function () {
    return {
      loading: false,
      loadingFade: false,
      percent: 0,
      percentString: '00.00',
      autoHide: false,
      debug: false,
      scrollLeft: 0,
      single: false,
      voteInfo: {}, // 稿件信息
      actionList: [],
      picList: [],
      picIndex: 0, // 图片的Index
      targetIndex: 0,
      picIndexInTarget: 0,
      currentRadio: 0,
      currentCheck: false,
      voetType: '', // 是打钩还是打分
      selectLimit: 0,
      radioMarks: [
        { label: 0, name: '不选此稿' },
        { label: 1, name: 1 },
        { label: 2, name: 2 },
        { label: 3, name: 3 },
        { label: 4, name: 4 },
        { label: 5, name: 5 },
        { label: 6, name: 6 },
        { label: 7, name: 7 },
        { label: 8, name: 8 },
        { label: 9, name: 9 },
        { label: 10, name: 10 },
      ],
      targetContent: {},
      hide: false, // 文字显示的淡出。淡入
      page: 1, // 默认是1开始
      pager: {
        currentPage: 1,
        total: 1,
        totalPage: 1,
      },
    }
  },
  created() {
    console.log('vote', this.$route.query)
    if (this.$route.query.type == 1) {
      this.pingfenType = 1
    } else if (this.$route.query.type == 0) {
      this.pingfenType = 0
    }
    this.reload()
  },
  methods: {
    onNothingClick() {},
    onAutoHideChange() {
      if (this.autoHide) {
        this.timeToHide()
      } else {
        this.showAndClearTimer()
      }
    },
    scrollToCenter() {
      console.log('list-width', this.$refs['galary-list'].scrollWidth)
      console.log('list-width', this.$refs['galary-item'][0].clientWidth)
      var targetLeft = 0
      for (var i = 0; i < this.picIndex; i++) {
        targetLeft += this.$refs['galary-item'][i].clientWidth + 4
      }
      console.log('this.picIndex', this.picIndex)
      targetLeft += this.$refs['galary-item'][this.picIndex].clientWidth / 2
      targetLeft -= this.$refs['galary-list'].clientWidth / 2
      console.log(targetLeft)
      console.log('galary-con', this.$refs['galary-con'].clientWidth)
      console.log('galary-list', this.$refs['galary-list'].scrollWidth)
      if (targetLeft < 0) {
        this.scrollLeft = 0
      } else if (-targetLeft + this.$refs['galary-list'].scrollWidth < this.$refs['galary-con'].clientWidth) {
        this.scrollLeft = this.$refs['galary-list'].scrollWidth - this.$refs['galary-con'].clientWidth
      } else {
        this.scrollLeft = targetLeft
      }
    },
    showLoading() {
      this.loading = true
    },
    hideLoading() {
      this.loadingFade = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    reload() {
      var thiz = this
      thiz.showLoading()
      RD.activityTopicVote()
        .id(this.$route.query.id)
        .voteInfo(thiz.pager.currentPage)
        .then((vote) => {
          var data = vote.info
          this.voetType = data.atv.type
          this.selectLimit = data.atv.limit
          this.pager = vote.pager
          var picList = []
          var preloadUrls = []
          if (data.targets.length != 0) {
            data.targets.forEach((target) => {
              target.single = target.pics.length == 1
              target.pics.forEach((p, pindex) => {
                var pic = {}
                pic.sImgUrl = RD.pic().id(p.id).sImage_URL()
                preloadUrls.push(pic.sImgUrl)
                pic.lImgUrl = RD.pic().id(p.id).lImage_URL()
                // preloadUrls.push(pic.lImgUrl)
                pic.picId = p.id
                pic.targetId = target.id
                pic.indexInTarget = pindex
                pic.show = 0
                pic.next = false
                picList.push(pic)
              })
            })
          } else {
            thiz.hideLoading()
            this.$alert('当前评选没有稿件，请先添加稿件再进行打分', '提示', {
              confirmButtonText: '返回首页',
              callback: (action) => {
                this.$router.push('/index.html')
              },
            })
            return
          }

          for (var i = 0; i < picList.length; i++) {
            preloadUrls.push(picList[i].lImgUrl)
            if (i > 3) {
              break
            }
          }
          console.log('preloadUrls', preloadUrls)
          thiz.voteInfo = JSON.parse(JSON.stringify(data))

          RDUtils.preloadImage(preloadUrls, (percent) => {
            var percentString = '' + percent
            if (percentString.indexOf('.') == -1) {
              percentString = percentString + '.00'
            }
            if (percentString.indexOf('.') == 1) {
              percentString = '0' + percentString
            }

            if (percentString.length == 4) {
              percentString = percentString + '0'
            }
            thiz.percent = percent
            thiz.percentString = percentString + '%'
          }).then(() => {
            thiz.picList = picList
            thiz.actionList = data.actions
            thiz.picIndex = 0
            thiz.changeIndexTo()
            thiz.picList.forEach((pic, index) => {
              if (index == this.picIndex) {
                pic.show = 2
              } else {
                pic.show = 0
              }
            })
            setTimeout(() => {
              thiz.hideLoading()
              if (this.autoHide) {
                timeout = setTimeout(() => {
                  this.hide = true
                }, 12000)
              }
            }, 2000)
          })
        })
    },
    onEnterControl() {
      console.log('onEnterControl')
      if (this.autoHide) {
        this.showAndClearTimer()
      }
    },
    onLeaveControl() {
      console.log('onLeaveControl')
      if (this.autoHide) {
        this.timeToHide()
      }
    },
    onHideClick() {
      this.hide = true
    },
    onShowClick() {
      this.hide = false
    },
    showAndClearTimer() {
      clearTimeout(timeout)
      this.hide = false
    },
    timeToHide() {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.hide = true
      }, 20000)
    },
    // 点击小图，切换
    onGalaryClick(targetPicIndex) {
      this.changeIndexTo(targetPicIndex)
    },
    changeIndexTo(newPicIndex) {
      const thiz = this
      console.log('changeIndexTo', newPicIndex, ' end')
      if (newPicIndex != undefined && newPicIndex != this.picIndex) {
        let oldIndex = this.picIndex
        this.picIndex = newPicIndex
        this.$nextTick(() => {
          this.scrollToCenter()
        })
        console.log('oldIndex', oldIndex)
        console.log('newPicIndex', newPicIndex)
        if (newPicIndex > oldIndex) {
          this.picList.forEach((pic, index) => {
            if (index == newPicIndex) {
              pic.show = 3
            } else if (index == oldIndex) {
              pic.show = 2
            } else {
              pic.show = 0
            }
          })
          setTimeout(() => {
            this.$nextTick(() => {
              this.picList.forEach((pic, index) => {
                if (index == newPicIndex) {
                  pic.show = 2
                  {
                    var sURL = pic.sImgUrl
                    pic.sImgUrl = sURL + '11111'
                    console.log('flash pic1 small', pic)
                    thiz.$nextTick(() => {
                      console.log('sImage flash')
                      pic.sImgUrl = sURL
                    })
                  }
                } else if (index == oldIndex) {
                  pic.show = 1
                } else {
                  pic.show = 0
                }
              })
            })
          }, 300)
        } else {
          this.picList.forEach((pic, index) => {
            if (index == newPicIndex) {
              pic.show = 1
            } else if (index == oldIndex) {
              pic.show = 2
            } else {
              pic.show = 0
            }
          })
          setTimeout(() => {
            this.$nextTick(() => {
              this.picList.forEach((pic, index) => {
                if (index == newPicIndex) {
                  pic.show = 2
                  {
                    var sURL = pic.sImgUrl
                    pic.sImgUrl = sURL + '11111'
                    console.log('flash pic1 small', pic)
                    thiz.$nextTick(() => {
                      console.log('sImage flash')
                      pic.sImgUrl = sURL
                    })
                  }
                } else if (index == oldIndex) {
                  pic.show = 3
                } else {
                  pic.show = 0
                }
              })
            })
          }, 300)
        }
      }
      console.log('this.picList[this.picIndex]', this.picList, this.picIndex, this.voteInfo)
      var targetId = this.picList[this.picIndex].targetId
      this.picIndexInTarget = this.picList[this.picIndex].indexInTarget
      this.voteInfo.targets.forEach((t, tIndex) => {
        console.log('now', t.id, targetId)
        if (t.id == targetId) {
          this.single = t.single
          this.targetIndex = tIndex
          this.targetContent.title = t.title
          this.targetContent.author = t.author
          this.targetContent.eventTime = t.eventTime
          this.targetContent.eventAddr = t.eventAddr
          this.targetContent.mainText = t.mainText
        }
      })
      console.log('mxx', this.targetContent)
      this.currentRadio = 0
      this.currentCheck = false
      for (var i = 0; i < this.actionList.length; i++) {
        if (this.actionList[i].activityTopicVoteTargetId == targetId) {
          this.currentRadio = this.actionList[i].value
          this.currentCheck = true
          break
        }
      }
    },
    // 打钩的变化
    onCheck() {
      console.log('currentCheck', this.picList[this.picIndex])
      var targetId = this.picList[this.picIndex].targetId
      var voteId = this.voteInfo.atv.id
      if (this.currentCheck) {
        RD.pure()
          .activityTopicVote()
          .id(voteId)
          .vote(targetId, 1)
          .then(() => {
            var obj = {}
            obj.activityTopicVoteTargetId = targetId
            obj.value = 1
            this.actionList.push(obj)
          })
          .catch((error) => {
            this.currentCheck = false
            this.$rtm.message.alert(error.msg, '出错了')
          })
      } else {
        RD.pure()
          .activityTopicVote()
          .id(voteId)
          .vote(targetId, 0)
          .then(() => {
            this.actionList.forEach((i, index) => {
              if (i.activityTopicVoteTargetId == targetId) {
                this.actionList.splice(index, 1)
              }
            })
          })
      }
    },
    //  打分改变分值
    onChangeRadio() {
      console.log('raio', this.currentRadio)
      var targetId = this.picList[this.picIndex].targetId
      var voteId = this.voteInfo.atv.id
      RD.activityTopicVote()
        .id(voteId)
        .vote(targetId, this.currentRadio)
        .then((data) => {
          this.actionList.forEach((i, index) => {
            if (i.activityTopicVoteTargetId == targetId) {
              this.actionList.splice(index, 1)
            }
          })
          var obj = {}
          obj.activityTopicVoteTargetId = targetId
          obj.value = this.currentRadio
          this.actionList.push(obj)
        })
    },
    // 左移
    onPicLeft(step) {
      console.log('onPicLeft', this.picIndex)
      if (this.picIndex != 0) {
        if (step) {
          this.changeIndexTo(this.picIndex - step < 0 ? 0 : this.picIndex - step)
        } else {
          this.changeIndexTo(this.picIndex - 1)
        }
      }
    },
    // 右移
    onPicRight(step) {
      if (this.picIndex != this.picList.length - 1) {
        if (step) {
          this.changeIndexTo(this.picIndex + step > this.picList.length - 1 ? this.picList.length - 1 : this.picIndex + step)
        } else {
          this.changeIndexTo(this.picIndex + 1)
        }
      }
    },
    // 完成评选
    onCompleteExit() {
      var thiz = this
      var voteId = this.voteInfo.atv.id
      RD.activityTopicVote()
        .id(voteId)
        .finish()
        .then(() => {
          this.$alert('已完成评选', '已完成').then(() => {
            window.close()
          })
        })
    },
    // 暂时退出
    onTempraryExit() {
      this.$message('已退出')
      window.close()
    },
    onPageChange(val) {
      var thiz = this
      thiz.pager.currentPage = val
      thiz.reload()
    },
  },
}
</script>

<style lang="less" scoped>
.loading-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: fix;
  user-select: none;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all 500ms ease-in-out;
  opacity: 1;
  .loading-title {
    height: 10vh;
    font-size: 4vh;
    line-height: 10vh;
    color: white;
    .percent {
      display: inline-block;
      width: 16vh;
      text-align: right;
    }
  }
  .loading-icon {
    height: 10vh;
    font-size: 8vh;
    line-height: 10vh;
    i {
      height: 10vh;
      font-size: 8vh;
      line-height: 10vh;
      color: white;
    }
  }
}
.loading-container.fade {
  opacity: 0.1;
}
.auto-hide-control {
  position: absolute;
  left: calc(50vw - 275px);
  top: 0px;
  width: 550px;
  height: 30px;
  border-bottom-left-radius: 2vw;
  border-bottom-right-radius: 2vw;
  background-color: rgba(35, 35, 35, 0.8);
  z-index: 999;
  display: flex;
  gap: 40px;
  justify-content: center;
  .page-container {
    height: 28px;
    overflow: hidden;
    border-radius: 5px;
  }
  .el-switch {
    margin-top: 5px;
    z-index: 999;
    ::v-deep .el-switch__label {
      color: red;
      text-shadow: 0 0 10px green;
    }
    ::v-deep .el-switch__label.is-active {
      color: white;
      text-shadow: 0 0 10px green;
    }
  }
  .button {
    color: black;
    font-size: 16px;
    margin-top: 4px;
    line-height: 20px;
    height: 20px;
    padding: 1px 20px;
    border-radius: 13px;
    background-color: white;
    cursor: pointer;
  }
}
.selectmanage {
  height: 100vh;
  background-color: #1e1f22;
  position: relative;
  overflow: hidden;
  .vote-main {
    height: 100vh;
    .pics-main {
      display: block;
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0px;
      top: 0px;
      .vote-item {
        display: none;
        width: 100vw;
        height: 100vh;
        transition: all 0.5s linear;
        position: absolute;
        left: 0px;
        top: -100vh;
        .image-large {
          background: no-repeat 50% / contain;
          background-color: black;
          width: 100vw;
          height: 100vh;
        }
      }
      .vote-item.left {
        display: block;
        left: -100vw;
        top: 0px;
      }
      .vote-item.current {
        display: block;
        left: 0px;
        top: 0px;
      }
      .vote-item.right {
        display: block;
        left: 100vw;
        top: 0px;
      }
      .pic-left {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 33vw;
        height: 100%;
        z-index: 99;
        .left-icon {
          position: absolute;
          top: 45vh;
          left: -5vh;
          width: 10vh;
          height: 10vh;
          background-color: rgba(8, 8, 9, 0.5);
          box-shadow: 0px 0px 1vh white;
          color: #fff;
          font-size: 5vh;
          line-height: 10vh;
          box-sizing: border-box;
          border-radius: 5vh;
          padding-right: 1vh;
          text-align: right;
          transition: all 200ms ease-in-out;
          opacity: 0;
          div {
            margin-top: 1.5rem;
          }
        }
        .left-icon:hover {
          top: 40vh;
          left: -10vh;
          width: 20vh;
          height: 20vh;
          font-size: 10vh;
          line-height: 20vh;
          padding-right: 2vh;
          border-radius: 10vh;
          background-color: rgba(8, 8, 9, 0.8);
          box-shadow: 0px 0px 0px white;
        }
      }
      .pic-left:hover {
        .left-icon {
          opacity: 1;
        }
      }
      .pic-right {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 33vh;
        height: 100%;
        z-index: 99;
        .right-icon {
          position: absolute;
          top: 45vh;
          right: -5vh;
          width: 10vh;
          height: 10vh;
          background-color: rgba(8, 8, 9, 0.5);
          box-shadow: 0px 0px 1vh white;
          color: #fff;
          font-size: 5vh;
          line-height: 10vh;
          box-sizing: border-box;
          border-radius: 5vh;
          padding-left: 1vh;
          text-align: left;
          transition: all 200ms ease-in-out;
          opacity: 0;
          div {
            margin-top: 1.5rem;
          }
        }
        .right-icon:hover {
          top: 40vh;
          right: -10vh;
          width: 20vh;
          height: 20vh;
          font-size: 10vh;
          line-height: 20vh;
          padding-left: 2vh;
          border-radius: 10vh;
          background-color: rgba(8, 8, 9, 0.8);
          box-shadow: 0px 0px 0px white;
        }
      }
      .pic-right:hover {
        .right-icon {
          opacity: 1;
        }
      }
    }
  }
  .top-hover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20vh;
    user-select: none;
    z-index: 99;
    .post-detail {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 25vw;
      max-height: 60vh;
      overflow: hidden;
      color: #fff;
      background-color: rgba(35, 35, 35, 0.9);
      overflow: hidden;
      padding: 25px;
      text-align: left;
      z-index: 1;
      transition: all 600ms ease-in-out;
      display: flex;
      flex-direction: column;
      .post-item-sum {
        display: block;
        color: #ff9c00;
        height: 5rem;
        font-size: 2.5rem;
      }
      .post-item {
        display: block;
        .label {
          color: #999;
          font-size: 1.8rem;
          height: 5rem;
          line-height: 5rem;
          margin-right: 1rem;
        }
        i {
          font-style: normal;
          font-size: 1.8rem;
          overflow-y: auto;
        }
      }
      .post-item.scroll {
        overflow-y: auto;
      }
    }
    .post-detail.hide {
      left: -50vw;
    }

    .action-detail {
      max-height: 50vh;
      max-width: 40vw;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: rgba(35, 35, 35, 0.9);
      padding: 3rem;
      text-align: left;
      z-index: 1;
      transition: all 1200ms ease-in-out;
      .info-line {
        display: flex;
        gap: 20px;
        line-height: 4rem;
        height: 4rem;
        .title {
          color: #999;
          font-size: 1.8rem;
          height: 3rem;
          line-height: 3rem;
        }
        .name {
          color: #fff;
          font-size: 1.6rem;
          height: 3rem;
          line-height: 3rem;
        }
      }

      .action-container {
        .info-line {
          display: flex;
          gap: 20px;
          line-height: 4rem;
          height: 4rem;
          .action-name,
          .action-info {
            text-align: center;
            font-size: 1.8rem;
            color: #ff9c00;
          }
        }
        .item-select {
          height: auto;
          position: relative;
          margin-top: 2rem;
          .item-select-check {
            width: 80%;
            margin: 0 auto;
            height: 5rem;
            line-height: 5rem;
            background-color: #ff9c00;
            text-align: center;
            border-radius: 0.5rem;
            .el-checkbox {
              color: #fff;
              ::v-deep.el-checkbox__label {
                font-size: 2rem;
              }
            }
          }
        }
        .item-btn {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          gap: 10px;
          div {
            width: calc(40% - 5px);
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            color: #000;
            background-color: #fff;
            font-size: 1.6rem;
            border-radius: 0.5rem;
            cursor: pointer;
          }
        }
        .value-select {
          border: 1px solid #ccc;
          height: auto;
          position: relative;
          margin-top: 1rem;
          .item-name {
            position: absolute;
            top: -1.5rem;
            left: 1rem;
            color: #fff;
            width: 8rem;
            text-align: center;
            font-size: 1.8rem;
            height: 4rem;
            background: rgba(35, 35, 35, 0.9);
          }
          .radiogroup {
            margin: 2rem;
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            ::v-deep.el-radio {
              color: #232427;
              cursor: pointer;
              height: 4rem;
              width: calc(33.33% - 2px);
              line-height: 4rem;
              margin: 0px;
              background: #f6f6f6;
              border: 1px solid #ccc;
              .el-radio__inner {
                width: 2rem;
                height: 2rem;
                margin-left: 1rem;
              }
            }
            ::v-deep.el-radio.large {
              width: calc(66.66% - 2px);
            }
          }
        }
      }
      .action-item {
        .item-name {
          color: #ff9c00;
          font-size: 2rem;
          text-align: center;
          height: 3rem;
          span {
            margin: 0rem 1rem;
          }
        }
        .item-select {
          margin: 2rem 0rem;
          .item-select-check {
            width: 80%;
            margin: 0 auto;
            height: 5rem;
            line-height: 5rem;
            background-color: #ff9c00;
            text-align: center;
            border-radius: 0.5rem;
            .el-checkbox {
              color: #fff;
              ::v-deep.el-checkbox__label {
                font-size: 2rem;
              }
            }
          }
        }
        .item-btn {
          display: flex;
          justify-content: space-around;
          div {
            width: 10rem;
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            color: #000;
            background-color: #fff;
            font-size: 1.6rem;
            border-radius: 0.5rem;
          }
        }
      }
    }
    .action-detail.hide {
      right: -55vw;
    }
  }

  .bottom-hover {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 25vh;
    .galary {
      position: absolute;
      bottom: 3vh;
      left: 0px;
      width: 100vw;
      height: 10vh;
      z-index: 99;
      display: flex;
      transition: all 600ms ease-in-out;
      .galary-left-2 {
        cursor: pointer;
        color: #fff;
        width: 4vh;
        height: 100%;
        .galary-icon {
          height: 100%;
          width: 100%;
          background-color: #545455;
          line-height: 10vh;
          font-size: 4vh;
        }
      }
      .galary-left {
        cursor: pointer;
        color: #fff;
        width: 4vh;
        margin: 0px 1px;
        height: 100%;
        .galary-icon {
          height: 100%;
          width: 100%;
          background-color: #545455;
          line-height: 10vh;
          font-size: 4vh;
        }
      }
      .galary-right {
        cursor: pointer;
        color: #fff;
        width: 4vh;
        margin: 0px 1px;
        height: 100%;
        .galary-icon {
          height: 100%;
          width: 100%;
          background-color: #545455;
          line-height: 10vh;
          font-size: 4vh;
        }
      }
      .galary-right-2 {
        cursor: pointer;
        color: #fff;
        width: 4vh;
        height: 100%;
        .galary-icon {
          height: 100%;
          width: 100%;
          background-color: #545455;
          line-height: 10vh;
          font-size: 4vh;
        }
      }
      .galary-con {
        flex: 1;
        height: 10vh;
        overflow: hidden;
        position: relative;
        .galary-list {
          height: calc(100% - 4px);
          border-top: 2px solid #545455;
          border-bottom: 2px solid #545455;
          transition: all 600ms ease-in-out;
          text-align: left;
          position: relative;
          left: 0px;
          top: -2px;
          white-space: nowrap;
          .galary-item {
            cursor: pointer;
            display: inline-block;
            border: 2px solid #545455;
            height: 100%;
            img {
              height: 100%;
            }
          }
          .galary-item.active {
            border: 2px solid red;
          }
        }
      }
    }
    .galary.hide {
      bottom: -40vh;
    }
    .page-container {
      position: absolute;
      bottom: 3vh;
      right: 0px;
      width: 100vw;
      height: 5vh;
      z-index: 99;
      transition: all 600ms ease-in-out;
      color: #fff;
      text-align: right;
      ::v-deep .el-pagination__jump {
        color: #000;
      }
    }
  }
}
</style>
